import React, { lazy, Suspense } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";

import "./App.css";

const delayImport = (importFunc, delay = 500) => {
  return new Promise((resolve) => {
    setTimeout(() => resolve(importFunc()), delay);
  });
};

const Home = lazy(() => delayImport(() => import("./pages")));
const Solutions = lazy(() =>
  delayImport(() => import("./pages/Solutions/index"))
);
const AboutUs = lazy(() => delayImport(() => import("./pages/About Us/index")));
const Resources = lazy(() =>
  delayImport(() => import("./pages/Resources/index"))
);
const Contact = lazy(() => delayImport(() => import("./pages/Contact/index")));
const PrivacyPolicy = lazy(() =>
  delayImport(() => import("./pages/Privacy Policy/index"))
);
const SiteMap = lazy(() => delayImport(() => import("./pages/Sitemap")));

const NotFound = lazy(() => delayImport(() => import("./pages/404")));

function App() {
  return (
    <Router>
      <Suspense fallback={<div></div>}>
        <Switch>
          <Route path="/" component={Home} exact />
          <Route path="/solutions" component={Solutions} />
          <Route path="/about-us" component={AboutUs} />
          <Route path="/resources" component={Resources} />
          <Route path="/contact-sales" component={Contact} />
          <Route path="/dcorp/privacy-policy" component={PrivacyPolicy} />
          <Route path="/sitemap" component={SiteMap} />
          <Route component={NotFound} />
        </Switch>
      </Suspense>
    </Router>
  );
}

export default App;
